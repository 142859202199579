import React from 'react';
import { Helmet } from 'react-helmet';

import ProjectPreviewView from '../../components/AnyOneProjectPreviewView';
import Layout from '../../layout/HomeLayout';

const anyOneProjectPreview = ({ location }: { location: Location }): React.ReactElement => {
  const requestId = parseInt(location.hash.replace('#', ''));
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Layout>
        <ProjectPreviewView requestId={requestId}></ProjectPreviewView>
      </Layout>
    </>
  );
};

export default anyOneProjectPreview;
